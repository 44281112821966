body {
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, “Helvetica Neue”, Arial, sans-serif, “Apple Color Emoji”, “Segoe UI Emoji”, “Segoe UI Symbol”;
  /* default is 1rem or 16px */
  font-size: 12px;
  font-weight: 400;
  /* line-height: 1.5; */
}
.btn {
  font-size: 12px;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  font-weight: bold;
}

.form-control-xs {
  height: 25px !important;
  padding: .125rem .25rem !important;
  font-size: .75rem !important;
  line-height: 1.5;
  border-radius: .2rem;
}

.noborder:focus {
  box-shadow: none !important;
}
   
/* Increase all font sizes on mobile */
@media (max-width: 767px) { 
   
  body {
  /* default is 1rem or 16px */
  font-size: 25px;
  }
   
}